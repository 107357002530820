<script>
import {getUrlPrefixToShow} from "@/helpers/functions";
import {ADMIN_, COORDINATOR_} from "@/helpers/constant/roles";
import {inject} from "vue";

export default {
  name: "referral-informations",
  setup(){
    const globals = inject("globals");

    return {globals}
  },
  methods: {
    getUrlPrefixToShow,
    getRoleTitle(){
      if (this.referral.role === "Coordinateur"){
        this.roleTitle = "Coordinateur"
      } else if(this.referral.role === "Superviseur"){
        this.roleTitle = "Superviseur"
      } else {
        this.roleTitle = "Administrateur"
      }
    },
  },
  props: {
    referral: {
      type: Object,
      required: true
    },
  },
  data(){
    return {
      roleTitle: "Membre"
    }
  },
  computed: {
    referralUrl(){
      this.getRoleTitle()
      if (this.globals.roles.IS_ADMIN || this.globals.roles.IS_SUPER_ADMIN){
        if (this.referral.role === "Coordinateur"){
          return getUrlPrefixToShow(ADMIN_, 'coordinator')
        } else if(this.referral.role === "Superviseur"){
          return getUrlPrefixToShow(ADMIN_, 'supervisor')
        } else {
          return getUrlPrefixToShow(ADMIN_, 'commercial')
        }
      } else {
        if (this.referral.role === "Superviseur"){
          return getUrlPrefixToShow(COORDINATOR_, 'supervisor')
        } else {
          return getUrlPrefixToShow(COORDINATOR_, 'commercial')
        }
      }
    },

  }
}
</script>

<template>
  <b-card>
    <b-card-header>
      <b-card-title>Information du {{roleTitle}}</b-card-title>
    </b-card-header>
    <b-card-body>
      <b-list-group flush class="list-group-fill-success">
        <b-list-group-item class="list-group-item-action">
          <i class=" bx bx-barcode align-middle me-2"></i>
          <router-link :to="referralUrl + this.referral.uniqId">{{referral.code}}</router-link>
        </b-list-group-item>
        <b-list-group-item class="list-group-item-action"><i class="bx bx-user align-middle me-2"></i> {{referral.fullName}}</b-list-group-item>
      </b-list-group>
    </b-card-body>
  </b-card>
</template>

<style scoped>

</style>