<script>
//   import API from "@/plugins/axios-method";
  // import Pagination from "@/components/dashboard/Pagination.vue";

import {COMMERCIAL, SUPERVISOR, USER} from "@/helpers/constant/roles";

export default {
    name: 'user-statistic-table-card',
    components: {},
    props: {
      users: {
        type: Array,
        default(){
         return []
        }
      },
      role: {
        type: String,
        default: 'supervisor'
      },
      // pageName: {
      //   type: String,
      //   default: null
      // }
    },
    data(){
      return {
      }
    },
    methods: {
      getAge(birthDate){
        const currentYear = new Date().getFullYear()
        const birthYear =  Number(birthDate.split('-')[0])

        return  currentYear - birthYear
      },
      getCreatedAndUpdatedDates(date){
        return date.split('T')[0]
      },
      openUpdateUserModal(user){
        this.$emit('openUpdateUserModal', user)
      },
      getRole(roles){
        switch (roles) {
          case 'ROLE_ADMIN':
            return 'admin'
          case 'ROLE_SUPERVISOR':
            return 'superviseur'
          case 'ROLE_COMMERCIAL':
            return 'commercial'
          case 'ROLE_USER':
            return 'membre'
          default:
            return 'membre'
        }
      },
      formatNumber(num) {
        if (num >= 1000000) {
          this.unit = 'M'
          return Math.ceil(num / 1000000);
        } else if (num >= 100000) {
          this.unit = 'K'
          return Math.ceil(num / 1000);
        } else {
          return num;
        }
      },
      formatPhoneNumber(phoneNumber){
        return phoneNumber?.split('237')[1]
      },
      formatRole(role){
        switch (role) {
          case SUPERVISOR:
            return "Superviseur"
          case COMMERCIAL:
            return "Commercial"
          case USER:
            return "Adhérent"
          default:
            break;
        }
      }
    },
    computed: {
      showUrl(){
        switch (this.role) {
          case 'supervisor':
            return '/admin/supervisors/'
          case 'commercial':
            return '/admin/commercials/'
          case 'member':
            return '/admin/members/'
          case 'coordinator':
            return '/admin/coordinators/'
          default:
            return '/admin/members/'
        }
      }
    }
  }
</script>

<template>
    <div class="table-responsive">
      <table class="table table-hover table-striped align-middle table-nowrap mb-0">
        <thead>
        <tr>
          <th scope="col">#</th>
          <!-- <th scope="col" v-if="pageName === 'commercials'" >Code</th> -->
          <th scope="col">Nom</th>
          <th scope="col">Téléphone</th>
          <th scope="col">Role</th>
          <th scope="col" v-if="role === 'commercial'">Adhérents Inscrits</th>
          <th scope="col" v-if="role === 'commercial'">Comptes Créés</th>
          <th scope="col" v-if="role === 'commercial'">Comptes Activés</th>
          <th scope="col">{{ role === 'member' ? 'Montant Epargné' : 'Epargne Adhérents' }}</th>
          <th scope="col">{{ role === 'member' ? 'Montant Inscription' : 'Inscription Adhérents' }}</th>
          <th scope="col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(user, index) in users" :key="index">
          <th scope="row">{{index + 1}}</th>
          <!-- <th v-if="pageName === 'commercials'">{{user.codes[0]?.name}}</th> -->
          <td>{{ user.fullName }}</td>
          <td>{{ formatPhoneNumber(user.phoneNumber) }}</td>
          <td>{{ formatRole(user.roles[0]) }}</td>
          <td class="text-center" v-if="role === 'commercial'">{{ user.registeredUsersCount}}</td>
          <td class="text-center" v-if="role === 'commercial'">{{ user.createdAccounts }}</td>
          <td class="text-center" v-if="role === 'commercial'">{{ user.activeGodsonAccounts }}</td>
          <td>{{ role === 'member' ? formatNumber(user.totalDeposit) : formatNumber(user.godsonTotalDeposit) }} XAF</td>
          <td>{{ role === 'member' ? formatNumber(user.totalRegistration) : formatNumber(user.godsonTotalRegistration)}} XAF</td>
          <td>
            <div class="hstack gap-3 fs-15">
              <router-link :to="showUrl + user.uniqId" class="scale-on-hover link-primary">
                <i class="ri-eye-fill"></i>
              </router-link>
<!--                  <a href="javascript:void(0);" class="link-primary"><i class="bx bx-dots-vertical-rounded"></i></a>-->
              <!-- <a href="javascript:void(0);" @click="openUpdateUserModal(user)" class="link-primary"><i class="ri-settings-4-line"></i></a> -->
<!--                  <a href="javascript:void(0);" class="link-danger"><i class="ri-delete-bin-5-line"></i></a>-->
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
</template>

<style scoped>

</style>